<template>
    <div class="h-90 mr-2">
        <div class="pb-10 h-100">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center ml-2">
                <div class="breadcrumbactive">
                    <div >
                        <span>Manage Organization </span>
                        <!-- <div>Create New</div> -->
                    </div>
                </div>
            </div>
            <div class="create-company-container w-100 h-100 ml-2 ">
                <div class="create-company-header">
                    <span class="fs-14 text-white">Select Creation Type </span>
                </div>
                <div class="mt-9">
                    <h1 class="text-center mb-9 text-secondary" style="font-size: 18px;">What are you Creating?</h1>
                    <div class="row no-gutters align-items-center justify-content-center">
                        <div class="select-type-box d-flex align-items-center justify-content-center flex-column pointer" :class="{'select-type-box-active':organisation}" @click="goToCompany('organisation')" style="width: 177px">
                            <img width="50px" height="50px" src="/static/images/organization-icon.svg" alt="Organization" />
                            <p class="text-secondary mb-0 pt-8" style="font-size: 14px;">Organization
                                <el-tooltip class="item" effect="dark"
                                    placement="top-start">
                                    <template slot="content">
                                        <span>Organization means parent organization <br> or parent company which is the owner of <br> multiple companies</span>
                                    </template>
                                    <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                </el-tooltip>
                            </p>
                        </div>
                        <div class="select-type-box ml-20 d-flex align-items-center justify-content-center flex-column pointer" :class="{'select-type-box-active':standalone}" @click="goToCompany('standalone')" style="width: 177px">
                            <img width="50px" height="50px" src="/static/images/company-icon.svg" alt="Standalone" />
                            <p class="text-secondary mb-0 pt-8" style="font-size: 14px;">Standalone Company</p>
                        </div>
                    </div>
                    <p style="font-size: 14px; font-weight: 700;" class="text-center text-dark mt-12">Create New Organization or Standalone Company to get Started</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            organisation: true,
            standalone: false,
        }
    },
    methods: {
        goToCompany(type) {
            if(type == 'organisation') {
                this.organisation = true;
                this.standalone = false;
                this.$router.push('/organization/create');
            } else if(type == 'standalone') {
                this.organisation = false;
                this.standalone = true;
                this.$router.push('/company/createcompany/standalonecompany');
            }
        }
    }, 
    mounted() {
        if(this.loggedInUser.has_organization_or_standalone) {
            if(this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin') {
                if(this.loggedInUser.user_has == 'standalone') {
                    this.$router.push('/company/listview');
                } else if(this.loggedInUser.user_has == 'organization') {
                    this.$router.push('/organization/listview');
                } else if(this.loggedInUser.user_has == 'companies') {
                    this.$router.push('/organization/listview');
                }
            } else if(this.loggedInUser.user_role == 'admin' || this.loggedInUser.user_role == 'employee') {
                if(this.loggedInUser.user_has == 'company') {
                    this.$router.push('/company/listview');
                }
            }
        }
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        }
    },
}
</script>

<style scoped>

.create-company-container {
    background-color: #fff;
    padding: 0 0 40px; 
    border-radius: 0 6px 0 0;
}
.create-company-header {
    padding: 9.9px 15px;
    background-color: #00448b;
    border-radius: 0 6px 0 0;
}
.select-type-box {
    padding: 31.5px 9.5px 12.5px 9px;
    border-radius: 4px;
    box-shadow: 0 0 10px 0 #eaeaea;
    background-color: #fff;
    border: solid 1px transparent;
    transition: border .3s;
}
.select-type-box-active {
    border: solid 1px #00448b;
}
</style>